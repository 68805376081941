








































































































































































































import { Component, Model } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { mixins } from 'vue-class-component';

  @Component({
    components: {

    },
  })
export default class TasqsListSortPopup extends mixins() {
  @Model('change', { type: String }) selectedSortType!: any

  sortType = 'deferment-desc'

  created() {
    this.sortType = JSON.parse(JSON.stringify(this.selectedSortType));
  }

  get isWorkTicketView() {
    return this.tasqListLevel === 'WorkTicket';
  }

  get isPadView() {
    return this.tasqListLevel === 'Pad';
  }

  get tasqListLevel() {
	  return tasqsListModule.tasqListLevel;
  }

  applySort() {
    this.$emit('applySortTasqPage', this.sortType);
  }

  cancelSort() {
    this.$emit('cancelSortTasqPage', this.sortType);
  }
}
